import React, {useEffect, useMemo, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { orderMutation, orderSaveMutation } from "../../apis/payment";
import FixedButton from "../../components/common/FixedButton";
import PaymentCardStock from "../../components/payment/PaymentCardStock";
import PaymentTerms from "../../components/payment/PaymentTerms";
import RegisterPoint from "../../components/payment/RegisterPoint";
import SelectPaymentMethod from "../../components/payment/SelectPaymentMethod";
import TotalPaymentPrice from "../../components/payment/TotalPaymentPrice";
import usePoint from "../../hooks/usePoint";
import { usePaymentValue } from "../../providers/PaymentProvider";
import {
  paymentMoneyState,
  paymentPayloadState,
  paymentPointState,
  useCouponState,
} from "../../recoil/atoms/payment";
import { alertModalState } from "../../utils/atom";
import { convertCouponIds, getThousandCommaPrice } from "../../utils/utils";
import RegisterGift from "../../components/payment/RegisterGift";
import gift from "../../pages/promotion/Gift";
import useMoney from "../../hooks/useMoney";
import RegisterMoney from "../../components/payment/RegisterMoney";
import PageLoadingComponent from "../../components/common/PageLoadingComponent";
import LoadingComponent from "../../components/common/LoadingComponent";
import ResultSuccess from "../../pages/payment/result/ResultSuccess";
import ResultReady from "../../pages/payment/result/ResultReady";

interface DigitalContainerProps {
  isPackage?: boolean;
}

function DigitalContainer({ isPackage }: DigitalContainerProps) {
  const { payment, totalPaymentPrice, totalCouponPrice }: any =
    usePaymentValue();
  const { data } = usePoint();
  const { data: money } = useMoney();
  const payload = useRecoilValue(paymentPayloadState);
  const resetPayload = useResetRecoilState(paymentPayloadState);
  const state: any = JSON.parse(localStorage.getItem("state") || "{}");
  const { type, cardId, amount, stockIds, amounts } = state;
  const useCoupon = useRecoilValue(useCouponState);
  const paymentPoint = useRecoilValue(paymentPointState);
  const paymentMoney = useRecoilValue(paymentMoneyState);
  const navigate = useNavigate();
  const setAlertModal = useSetRecoilState(alertModalState);

  const [payLoading, setPayLoading] = useState<boolean>(false);
  const handleSubmit = async () => {
    setPayLoading(true);
    if (payload.method === "CARD" && totalPaymentPrice > 0 && totalPaymentPrice < 100) {
      setAlertModal({
        title: "결제금액 미달",
        text: "카드 결제 시 100원 미만은 결제가 불가능합니다.",
      });
      setPayLoading(false);
      return;
    }

    if (payload.method === "TRANS" && totalPaymentPrice > 0 && totalPaymentPrice < 150) {
      setAlertModal({
        title: "결제금액 미달",
        text: "실시간 계좌이체 시 150원 미만은 결제가 불가능합니다.",
      });
      setPayLoading(false);
      return;
    }

    let cardInfo;
    let refundInfo;
    let giftInfo;

    if (type === "digital-pack") {
      cardInfo = {
        card_id: state.cardId,
        card_amount: state.amount,
      };
    } else {
      cardInfo = {
        card_stock_ids: state.stockIds,
        card_amounts: state.amounts,
      };
    }

    if (payload.method === "VBANK") {
      refundInfo = {
        refund_bank: payload.refundInfo?.bank,
        refund_account: payload.refundInfo?.account,
        refund_name: payload.refundInfo?.owner,
      };
    }

    if(state.gift == 1){
      giftInfo = {
        gift_type: payload.giftInfo?.type,
        gift_name: payload.giftInfo?.name,
        gift_id: payload.giftInfo?.id,
      };
    }

/*    if(type === "digital-pack" && state.gift != 1 && totalPaymentPrice == 0) {
      navigate('/payment/result/ready', { replace: true,
        state: {
          cardInfo: cardInfo,
          useCoupon: useCoupon,
          paymentPoint: paymentPoint,
          paymentMoney: paymentMoney,
          payload: payload,
          state: state,
          isPackage: isPackage,
          refundInfo: refundInfo,
          giftInfo: giftInfo,
          totalPaymentPrice: totalPaymentPrice,
        }
      })
    }else {*/
      const {data} = await orderSaveMutation({
        ...cardInfo,
        user_coupon_ids: convertCouponIds(useCoupon),
        point: String(paymentPoint),
        money: String(paymentMoney),
        payment_method: payload?.method as any,
        is_promotion: 0,
        gift: state.gift,
        ...(isPackage && {is_digital: 1}),
        ...(refundInfo && {...refundInfo}),
        ...(giftInfo && {...giftInfo}),
      });

      if (!data.success) {
        setAlertModal({
          title: "상품 구매 불가",
          text: data.alert,
        });
        setPayLoading(false);
        return;
      }

      if (totalPaymentPrice === 0) {
        navigate(`/payment/result?success=true&id=${data?.data?.order_id}`);
        return;
      }

      orderMutation(data?.data?.order_id);
    //}
  };

  const isValid = useMemo(() => {
    if (!payload?.terms?.cancelTerm) return false;
    if (!payload?.terms?.purchaseTerm) return false;
    if (totalPaymentPrice !== 0 && !payload.method) return false;
    if (state.gift == 1){
      if (!payload.giftInfo?.type) return false;
      if (!payload.giftInfo?.name) return false;
      if (payload.giftInfo?.type != 'kakao'){
        if(!payload.giftInfo?.id) return false;
      }
    }
    if (payload?.method === "VBANK" && !payload.refundInfo) return false;
    return true;
  }, [payload, totalPaymentPrice]);

  useEffect(() => {
    return resetPayload;
  }, []);

  const cardList = useMemo(() => {
    if (payment.card_stocks) {
      return payment.card_stocks;
    }

    return payment.cards;
  }, [payment]);

  if(payLoading) {
    return <LoadingComponent/>
  }

  return (
    <Wrapper>
      {cardList.map((card: any) => (
        <PaymentCardStock key={card.id} cardStock={card} isCoupon={true} paymentPrice={totalPaymentPrice}
                          paymentType={card.payment_type} ticketPrice={card.ticket_price} stateAmount={state.amount} isDigital />
      ))}
      {(cardList[0]?.is_pack == 1 && cardList[0].id != 4796) &&
        <RegisterPoint
          myPoint={data?.total_point || 0}
          paymentPrice={totalPaymentPrice}
          couponPrice={totalCouponPrice}
          productPrice={payment?.product_price}
          moneyPrice={paymentMoney}
        />
      }
      <RegisterMoney
          myMoney={money?.total_money || 0}
          pointPrice={paymentPoint}
          paymentPrice={totalPaymentPrice}
          couponPrice={totalCouponPrice}
          productPrice={payment?.product_price}
      />

      <TotalPaymentPrice
        couponPrice={totalCouponPrice}
        deliveryFee={payment.delivery_fee ?? 0}
        paymentPrice={totalPaymentPrice}
        point={paymentPoint}
        money={paymentMoney}
        totalPrice={payment.product_price ?? 0}
      />
      {state.gift == 1 && <RegisterGift />}
      {totalPaymentPrice !== 0 && <SelectPaymentMethod />}
      <PaymentTerms />
      <FixedButton onClick={handleSubmit} disabled={!isValid}>
        {getThousandCommaPrice(totalPaymentPrice)}원 결제
      </FixedButton>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 90px;
`;

export default DigitalContainer;
