import styled from "styled-components";
import { ReactComponent as HeartEmptyIcon } from "../../assets/icons/heart-empty2-icon.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart-active-icon.svg";
import { BasicContainer, FixedBottomWrapper } from "../layout/CommonStyle";
import Button from "../elements/Button";
import {
  useProductDetail,
  useProductDetailAction,
} from "../../providers/ProductDetailProvider";
import {getThousandCommaPrice, renderPrice} from "../../utils/utils";
import { useSetRecoilState } from "recoil";
import { digitalPackagePaymentModalState } from "../../recoil/atoms/modal";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import {alertModalState} from "../../utils/atom";
import {useEffect, useState} from "react";

function DigitalPacakgeFooter() {
  const { low_card_stock, card, open_amount, total_amount, remain_amount, type_ticket} =
    useProductDetail() as any;
  const { toggleLike } = useProductDetailAction();
  const setPaymentModal = useSetRecoilState(digitalPackagePaymentModalState);
  const { checkLogin } = useUser();
  const { cardId } = useParams();
  const setAlertModal = useSetRecoilState(alertModalState);
  // 로그인 check 후 정상동작 시에 결제모달 노출
  const handleClickButton = checkLogin((gift : number) => {
    if(card.is_ticket == 1 && card.remain_user_ticket == 0){
      setAlertModal({
        title: "상품 구매 불가",
        text: "구매 가능 티켓이 없습니다.",
      });
      return;
    }
    if(card.user_order_able_max == 0){
      setAlertModal({
        title: "상품 구매 불가",
        text: "계정 당 구매수를 초과했어요.",
      });
    }else {
      setPaymentModal({
        stockId: cardId as string,
        title: card.name,
        price: card.price,
        gift: gift,
        userOrderAbleMax: card.user_order_able_max,
        amount: {
          open: open_amount,
          total: total_amount,
          remain: remain_amount,
        },
        isTicket: card.is_ticket,
        isAddCard:card.is_add_card,
        paymentType: card.payment_type,
        typeTicket:card.type_ticket,
        typePrice:card.type_price,
        remainUserTicket: card.remain_user_ticket,
      });
    }
  });
  // 로그인 check 후 정상동작 시에 카드 관심상품 등록 및 해제
  const handleClickLike = checkLogin(() => {
    toggleLike(card.id);
  });
  return (
    <FixedBottomWrapper>
      <Wrapper>
        <div className="heart-wrapper">
          {card.my_like === 1 ? (
            <HeartIcon className="heart-icon" onClick={handleClickLike} />
          ) : (
            <HeartEmptyIcon className="heart-icon" onClick={handleClickLike} />
          )}
        </div>
        <div className="buttons">
          {(card.is_ticket == 1) ?
              (<>
                <Button
                    styleType="sub"
                    direction="column"
                    onClick={() => {
                      handleClickButton(0)
                    }}
                >
                  <p>구매하기</p>
                  <p className="button-text sub">{getThousandCommaPrice(card.ticket_price)}장</p>
                </Button>
              </>
              ): card.payment_type == 1 && localStorage.getItem('tab') == '1' ? (
                  <>
                    <Button
                        direction="column"
                        onClick={() => {
                          handleClickButton(1)
                        }}
                    >
                      <p>선물하기</p>
                      <p className="button-text">
                        {renderPrice(card.type_price)}
                      </p>
                    </Button>
                    <Button
                        styleType="sub"
                        direction="column"
                        onClick={() => {
                          handleClickButton(0)
                        }}
                    >
                      <p>구매하기</p>
                      <p className="button-text sub">{renderPrice(card.type_price)}</p>
                    </Button>
                  </>
              ) : card.payment_type == 1 && localStorage.getItem('tab') == '3' ? (
                  <>

                    <Button
                        styleType="sub"
                        direction="column"
                        onClick={() => {
                          handleClickButton(0)
                        }}
                    >
                      <p>구매하기</p>
                      <p className="button-text sub">{getThousandCommaPrice(card.type_ticket)}장</p>
                    </Button>
                  </>
              ) : (
                  <>
                    <Button
                        direction="column"
                        onClick={() => {
                          handleClickButton(1)
                        }}
                    >
                      <p>선물하기</p>
                      <p className="button-text">
                        {renderPrice(card.price)}
                      </p>
                    </Button>
                    <Button
                        styleType="sub"
                        direction="column"
                        onClick={() => {
                          handleClickButton(0)
                        }}
                    >
                      <p>구매하기</p>
                      <p className="button-text sub">{renderPrice(card.price)}</p>
                    </Button>
                  </>
              )}
        </div>
        {/*<div className="buttons">*/}
        {/*  {card.is_ticket == 1 ?*/}
        {/*      (<>*/}
        {/*        <Button*/}
        {/*            styleType="sub"*/}
        {/*            direction="column"*/}
        {/*            onClick={() => {*/}
        {/*              handleClickButton(0)*/}
        {/*            }}*/}
        {/*        >*/}
        {/*          <p>구매하기</p>*/}
        {/*          <p className="button-text sub">{getThousandCommaPrice(card.ticket_price)}장</p>*/}
        {/*        </Button>*/}
        {/*      </>)*/}
        {/*      : (*/}
        {/*  <>*/}
        {/*  <Button*/}
        {/*    direction="column"*/}
        {/*    onClick={() => {*/}
        {/*      handleClickButton(1)*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <p>선물하기</p>*/}
        {/*    <p className="button-text">*/}
        {/*      {renderPrice(card.price)}*/}
        {/*    </p>*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    styleType="sub"*/}
        {/*    direction="column"*/}
        {/*    onClick={() => {*/}
        {/*      handleClickButton(0)*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <p>구매하기</p>*/}
        {/*    <p className="button-text sub">{renderPrice(card.price)}</p>*/}
        {/*  </Button>*/}
        {/*  </>*/}
        {/*  )}*/}
        {/*</div>*/}
      </Wrapper>
    </FixedBottomWrapper>
  );
}

const Wrapper = styled(BasicContainer)`
  gap: 17px;

  & .heart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .heart-icon {
    width: 28px;
    height: 28px;
  }

  & .button-wrapper {
    flex: 1;
  }

  & .buttons {
    flex: 1;
    display: flex;
    gap: 5px;
  }

  & .button-text {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(243, 243, 243, 1);
  }

  & .button-text.sub {
    color: rgba(58, 58, 58, 1);
  }
`;

export default DigitalPacakgeFooter;
