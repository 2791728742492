import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";
import CardConditions from "./CardConditions";

interface CommonSummaryInfoProps {
  categoryName?: string;
  name?: string;
  subName?: string;
  price?: number;
  conditions?: string;
  cardName?: string;
  isTicket?: number;
}

function CommonSummaryInfo({
  categoryName,
  name,
  subName,
  price,
  conditions,
  cardName,
  isTicket,
}: CommonSummaryInfoProps) {
  return (
    <Wrapper>
      {categoryName && <div className="category-name">{categoryName}</div>}
      {name && <div className="card-name">[{name}]{cardName && cardName}</div>}
      {subName && <div className="card-sub-name ellipsis">{subName}</div>}
      {price && (
        <div className="card-price">{getThousandCommaPrice(price)}
          {isTicket == 1 || localStorage.getItem('tab') == '3'  ? "장" : "원"}</div>
      )}
      {conditions && <CardConditions condition={conditions} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  & .category-name {
    margin-bottom: 8px;

    color: rgba(93, 93, 93, 1);
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;

    /* display: inline; */
    width: max-content;
    white-space: nowrap;

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  & .card-name {
    display: inline;

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;

    width: max-content;
    //white-space: nowrap;

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  & .card-sub-name {
    margin-top: 6px;

    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }

  & .card-price {
    margin-top: 6px;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }
`;

export default CommonSummaryInfo;
