import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import styled from "styled-components";
import { categoryModalState } from "../../recoil/atoms/modal";
import ModalHeader from "../common/ModalHeader";
import { CommonBottomModalContainer } from "../layout/CommonStyle";
import ModalWrapper from "./ModalWrapper";

function CategoriesModal() {
  const modal = useRecoilValue(categoryModalState);
  const reset = useResetRecoilState(categoryModalState);

  const handleClickCategory = (category: any) => () => {
    reset();
    category.is_gacha == 1 ? window.location.href='/main?tab=2&d_tab='+category.id : window.location.href='/main?tab=1&d_tab='+category.id;
  };

    const [origin, setOrigin] = useState<string>("");

    useEffect(() => {
        if (modal) {
            setOrigin(location.pathname);
        }
    }, [modal]);

    useEffect(() => {
        if (origin !== "" && location.pathname !== origin) {
            reset();
        }
    }, [location.pathname]);

    if (!modal) return null;

  return (
    <ModalWrapper position="bottom" onClose={reset}>
      <Wrapper>
        <ModalHeader title="카테고리 전체보기" onClose={reset} />
        <div className="list-wrapper mt-20">
          {modal.categories.map((category) => (
            <div
              className="category-wrapper"
              onClick={handleClickCategory(category)}
              key={category.id}
            >
              <img src={category.image_url} />
              <p className="mt-8">{category.name}</p>
            </div>
          ))}
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  height: 90%;
  overflow-y: scroll;

  & .list-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 20px;
  }

  & .category-wrapper {
    cursor: pointer;

    & > img {
      width: 100%;
      aspect-ratio: calc(90 / 123);
      object-fit: contain;
    }

    & > p {
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.20000000298023224px;
      text-align: center;
      color: rgba(30, 30, 30, 1);
    }
  }
`;

export default CategoriesModal;
