import React from "react";
import styled from "styled-components";
import Divide from "../../components/common/Divide";
import ProductImages from "../../components/detail/ProductImages";
import ProductInfo from "../../components/detail/ProductInfo";
import ProductPriceInfo from "../../components/detail/ProductPriceInfo";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import ProductExplanationContainer from "./ProductExplanationContainer";
import ProductClassCountInfo from "../../components/detail/ProductClassCountInfo";

function DigitalPackageContainer() {
  const product = useProductDetail() as any;

  return (
    <Wrapper>
      <ProductImages images={product.card_images} height={"100%"} width={"100%"}/>
      <ProductInfo info={product.card} />
      <ProductPriceInfo
        className="mt-28"
        price={product?.card?.price || 0}
        payment_type={product?.card?.payment_type || 0}
        type_ticket={product?.card?.type_ticket || 0}
        type_price={product?.card?.type_price || 0}
        amount={{
          total: product.total_amount,
          open: product.total_amount - product.remain_amount,
          remain: product.remain_amount,
        }}
        isTicket={product?.card?.is_ticket || 0}
      />
        {product?.card_classes && product?.card_classes[0].card_class && (
            <>
                <Divide margin={20} />
                <ProductClassCountInfo
                    cardClasses={product?.card_classes}
                />
            </>
        )}
      <Divide margin={20} />
      <ProductExplanationContainer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 100px;
`;

export default DigitalPackageContainer;
