import React, {useMemo, useState} from "react";
import styled from "styled-components";
import useCouponList from "../../hooks/useCouponList";
import {customToast, getThousandCommaPrice, onChangeNumberType} from "../../utils/utils";
import PaymentButton from "../payment/PaymentButton";
import useSWR from "swr";
import useConfig from "../../hooks/useConfig";
import Input from "../elements/Input";
import {useRecoilState} from "recoil";
import {paymentPointState} from "../../recoil/atoms/payment";
import { ReactComponent as MinusIcon } from "../../assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/file-plus-icon.svg";
interface ApplyCouponComponentProps {
  price: number;
  couponPrice?: number;
  onApply: () => void;
  text: string;
  pointPrice?: number;
  moneyPrice?: number;
  paymentPrice?: number;
  isCouponView?: boolean;
  isTicket?: number;
  paymentType?: number;
  ticketPrice?: number;
  stateAmount?: number;
}

function ApplyCouponComponent({
  couponPrice,
  onApply,
  price,
  text,
  pointPrice,
  moneyPrice,
  paymentPrice,
  isCouponView,
  isTicket,
  paymentType,
  ticketPrice,
  stateAmount
}: ApplyCouponComponentProps) {
  const { data } = useCouponList();
  const { data : config } = useConfig('dc_coupon_available_min_money');
  const dcAvailableMinMoney = config?.config?.value;
  const [paymentPoint, setPaymentPoint] = useRecoilState(paymentPointState);
  const [count, setCount] = useState(0);

  const isCoupon = useMemo(() => {
    if (!data) return false;
    if ((data?.user_coupons?.data || []).length === 0) return false;
    if (price < dcAvailableMinMoney) return false;
    return true;
  }, [data, config]);
  const handleClickCount = (type: "plus" | "minus") => () => {
    let productMaxTicket = Math.floor((stateAmount ? stateAmount:0) * (ticketPrice?ticketPrice:0))
    let ticketCount = Math.floor(data?.total_ticket / (ticketPrice?ticketPrice:0));
    let userMaxTicket = (ticketPrice ? ticketPrice:0) * ticketCount;
    let productTicketPrice = price / (stateAmount ? stateAmount:0)
    let paymentCount = count / (ticketPrice ?ticketPrice:0);
    if (type === "plus") {
      if(productMaxTicket <= count){
        customToast("티켓 최대 사용양을 초과했어요.");
        setCount(productMaxTicket);
        return;
      }else{
        setCount(count + (ticketPrice? ticketPrice :0));
      }
      if(userMaxTicket <= count){
          customToast("보유 티켓을 초과했어요.");
          setCount(userMaxTicket);
          return;
      }else{
        setCount(count + (ticketPrice? ticketPrice :0));
      }

    } else {
      if (count === 0) {
        customToast("수량은 1개 이상 선택할 수 있어요.");
        return;
      }

      setCount(count - (ticketPrice? ticketPrice :0));
    }
  };
  return (
      <Wrapper>
        {isTicket != 1 &&
            <div className="box">
              <div className="box-label">상품 구매 금액</div>
              <div className="box-value">{getThousandCommaPrice(price)}{isTicket == 1 || localStorage.getItem('tab') == '3' ? "장" : "원"}</div>
            </div>
        }
        {isCouponView &&
            <div className="box">
              <div className="box-label">쿠폰</div>
              <div className="coupon-wrapper">
                {couponPrice && (
                    <div className="box-value">
                      {getThousandCommaPrice(Number(couponPrice))}원 할인
                    </div>
                )}

                {!isCoupon && price < dcAvailableMinMoney &&
                    <div className="box-value">{getThousandCommaPrice(dcAvailableMinMoney)}원부터 사용가능</div>}
                {!isCoupon && price >= dcAvailableMinMoney && <div className="box-value">보유 쿠폰이 없습니다.</div>}

                <PaymentButton disabled={!isCoupon} onClick={onApply}>
                  {text}
                </PaymentButton>
              </div>
            </div>
        }
      </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;

  & .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .box-label {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }

  & .box-value {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(93, 93, 93, 1);
  }

  & .coupon-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

export default ApplyCouponComponent;
