import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";

interface ProductPriceInfoProps {
  price: number;
  amount: {
    total: number;
    open: number;
    remain: number;
  };
  isTicket: number;
  className?: string;
  type_ticket: number;
  payment_type: number;
  type_price: number;
}

function ProductPriceInfo({ price, amount, className, isTicket, type_ticket, payment_type, type_price }: ProductPriceInfoProps) {
  return (
      <Wrapper className={className ? className : ""}>
          <div className="box">
              {isTicket == 1 ? (
                  <>
                  <label className="label">판매가</label>
                  <p className="value">{getThousandCommaPrice(price)}장</p>
                  </>
              ): payment_type == 1 && localStorage.getItem('tab') == '1' ? (
                  <>
                  <label className="label">판매가</label>
                  <p className="value">{getThousandCommaPrice(type_price)}원</p>
                  </>
              ) :payment_type == 1 && localStorage.getItem('tab') == '3' ? (
                  <>
                  <label className="label">판매가</label>
                  <p className="value">{getThousandCommaPrice(type_ticket)}장</p>
                  </>
              ):(
                  <>
                      <label className="label">판매가</label>
                      <p className="value">{getThousandCommaPrice(price)}원</p>
                  </>
              )}
          </div>
          <div className="box">
              <label className="label">개봉율</label>
              <p className="value">
                  {amount.open}/{amount.total}
              </p>
          </div>
      </Wrapper>
  );
}

const Wrapper = styled.div`
    display: flex;

    & .box {
        flex: 1;
    }

    & .label {
        font-family: Noto Sans KR;
        font-size: 11px;
        font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;

    color: rgba(172, 172, 172, 1);
  }

  & .value {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
    margin-top: 6px;
  }
`;

export default ProductPriceInfo;
