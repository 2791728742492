import React, { useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { PaymentCardStockType } from "../../providers/PaymentProvider";
import { couponModalState } from "../../recoil/atoms/modal";
import {paymentMoneyState, paymentPointState, useCouponState} from "../../recoil/atoms/payment";
import { getThousandCommaPrice } from "../../utils/utils";
import CommonSummaryInfo from "../card/CommonSummaryInfo";
import SummaryCardImage from "../card/SummaryCardImage";
import ApplyCouponComponent from "../common/ApplyCouponComponent";
import Divide from "../common/Divide";
import PaymentButton from "./PaymentButton";
import StockUserProfile from "./StockUserProfile";
import ProductImages from "../detail/ProductImages";
import ProductInfo from "../detail/ProductInfo";

interface PaymentCardStockProps {
  cardStock: PaymentCardStockType;
  isCoupon: boolean;
  isDigital?: boolean;
  paymentPrice: number;
  isTicket?: number;
  paymentType?: number;
  ticketPrice?: number;
  stateAmount?: number;
}

function PaymentCardStock({
  cardStock,
  isCoupon,
  isDigital,
  paymentPrice,
  isTicket,
  paymentType,
  ticketPrice,
  stateAmount
}: PaymentCardStockProps) {
  const setCouponModal = useSetRecoilState(couponModalState);
  const [coupons, setCoupons] = useRecoilState(useCouponState);

  const paymentPoint = useRecoilValue(paymentPointState);
  const paymentMoney = useRecoilValue(paymentMoneyState);
  const isApplyCoupon = useMemo(() => {
    return !!coupons[cardStock.id];
  }, [coupons, cardStock.id]);

  const couponText = useMemo(() => {
    return coupons[cardStock.id] ? "취소" : "쿠폰 선택";
  }, [coupons, cardStock.id]);

  const couponPrice = useMemo(() => {
    if (!coupons[cardStock.id]) return undefined;
    return coupons[cardStock.id].price;
  }, [coupons, cardStock.id]);

  const openCouponList = () => {
    setCouponModal({
      stockId: Number(cardStock.id),
      price: Number(cardStock.price),
    });
  };

  const cancelCoupon = () => {
    const newCoupons = { ...coupons };

    delete newCoupons[cardStock.id];

    setCoupons(newCoupons);
  };

  const handleCoupon = () => {
    if (isApplyCoupon) {
      cancelCoupon();
    } else {
      openCouponList();
    }
  };
  const type = useMemo(() => {
    if (cardStock.is_digital === 1 || isDigital) {
      if (cardStock.is_pack === 1) {
        return "digitalPack";
      } else {
        return "digital";
      }
    }

    if (cardStock.is_pack === 1) {
      return "goodsPack";
    }

    return "goods";
  }, [cardStock.is_digital, cardStock.is_pack, isDigital]);
  const subName = useMemo(() => {
    if (type === "goods" || type === "digital") return;
    return `${cardStock.amount}개`;
  }, [type, cardStock.amount]);

  return (
    <Wrapper>
      <StockUserProfile
        nickname={cardStock.nickname}
        type={type}
        profileUrl={cardStock.profile_url}
      />
      <div className="card-stock-info-wrapper">
        <SummaryCardImage imageUrl={cardStock.image_url} width={cardStock.is_pack == 1 ? "100%" : ""}
          height={cardStock.is_pack == 1 ? "100%" : ""} />
        <CommonSummaryInfo
          categoryName={cardStock.category_name}
          name={cardStock.name}
          cardName={cardStock.card_name}
          price={cardStock.price}
          subName={subName}
          isTicket={isTicket}
        />
      </div>

      <ApplyCouponComponent
        couponPrice={couponPrice}
        pointPrice={paymentPoint}
        moneyPrice={paymentMoney}
        price={cardStock.payment_price}
        paymentPrice={paymentPrice}
        onApply={handleCoupon}
        text={couponText}
        isCouponView={isCoupon}
        isTicket={isTicket}
        paymentType={paymentType}
        ticketPrice={ticketPrice}
        stateAmount={stateAmount}
      />

      {isTicket != 1 &&
      <div className="divide-wrapper mt-16">
        <Divide />
      </div>
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .divide-wrapper {
    position: relative;
    width: calc(100% + 40px);
    right: 20px;
  }

  & .card-stock-info-wrapper {
    padding: 14px 0;
    display: flex;
    gap: 12px;
  }
  
  & .ticket_image {
    aspect-ratio: 1;

    overflow-y: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 200px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  background: rgba(243, 243, 243, 1);
  border-radius: 15px;

  width: 100%;
  aspect-ratio: 1;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 55.5%;
    aspect-ratio: calc(120 / 217);
    object-fit: contain;
    height: 90%;
  }
`;
export default PaymentCardStock;
