import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { termModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";

const TermModal = () => {
  const [modal, setModal] = useRecoilState(termModalState);

  const wrapperRef = useRef(null);

  const { data } = useSWR(modal && `/terms?title=${modal?.type}`);

  useEffect(() => {
    if (wrapperRef.current !== null && data) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.display = "block";
        setTimeout(() => {
          //@ts-ignore
          wrapperRef.current.style.opacity = "1";
        });
      });
    }
  }, [wrapperRef, data, modal]);

  const handleConfirm = () => {
    setTimeout(() => {
      //@ts-ignore
      wrapperRef.current.style.opacity = "0";
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.display = "none";
        setModal(null);
      }, 300);
    });
  };

    const [origin, setOrigin] = useState<string>("");

    useEffect(() => {
        if (modal) {
            setOrigin(location.pathname);
        }
    }, [modal]);

    useEffect(() => {
        if (origin !== "" && location.pathname !== origin) {
            handleConfirm();
        }
    }, [location.pathname]);

    if (modal === null) return null;

  return (
    <ModalWrapper position="none" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef} className="ql-snow">
        <PopupTitle>
          {modal?.title || modal.type}
          <Close onClick={handleConfirm} />
        </PopupTitle>
        <PopupText
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: data?.terms }}
        />
      </Wrapper>
    </ModalWrapper>
  );
};

export default TermModal;

export const PopupText = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  padding: 16px 20px;
  overflow: auto;
  
  & > p > img {
    width: 100%;
  }
`;

const PopupTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 0;
  transition: all 0.3s;
  display: none;
  opacity: 0;
`;
