import React, { useEffect, useMemo, useState } from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import styled from "styled-components";
import {confirmModalState, digitalPackagePaymentModalState} from "../../../recoil/atoms/modal";
import { customToast, getThousandCommaPrice } from "../../../utils/utils";
import CommonHeader from "../../common/CommonHeader";
import Divide from "../../common/Divide";
import Button from "../../elements/Button";
import { CommonBottomModalContainer } from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/file-plus-icon.svg";
import { useNavigate } from "react-router-dom";
import {alertModalState} from "../../../utils/atom";
import { ReactComponent as Event } from "../../../assets/icons/promotion-event-icon2.svg";

function DigitalPackagePaymentModal() {
  const [modal, setModal] = useRecoilState(digitalPackagePaymentModalState);

  const [count, setCount] = useState(1);
  const setConfirmModal = useSetRecoilState(confirmModalState);
  const navigate = useNavigate();
  const tab = localStorage.getItem('tab');
  const handleClickCount = (type: "plus" | "minus") => () => {
    if (!modal) return;
    if (type === "plus") {
      if (modal?.amount.remain <= count) {
        customToast("최대 재고 수량을 초과했어요.");
        return;
      }
      if(modal?.userOrderAbleMax != -1 && modal?.userOrderAbleMax <= count){
        customToast("계정 당 구매수를 초과했어요.");
        return;
      }
      if((modal?.isTicket == 1 || (modal?.paymentType == 1 && tab == '3')) && modal?.remainUserTicket < (modal?.price * (count + 1))){
        customToast("보유 티켓수가 부족합니다.");
        return;
      }
      setCount(count + 1);
    } else {
      if (count === 0) {
        customToast("수량은 1개 이상 선택할 수 있어요.");
        return;
      }

      setCount(count - 1);
    }
  };
  const handleButtonCount = (buttonCount : number) => () => {
    if(buttonCount === 100 && (modal?.amount?.remain ?? 0) < buttonCount+10 && modal?.isAddCard == 1){
      setConfirmModal({
        title: "프로모션 구매 안내사항",
        description:
            "재고 수량이 110개 미만으로<br/>" +
            "프로모션 혜택을 받을 수 없습니다.<br/>" +
            "구매를 진행하시겠습니까? <br/> " +
            "<p style='color: #FF7C7C; font-size:14px'>결제 시 수량을 다시 한번 확인해주세요!</p>",
        onConfirm: () => {
          if (!modal) return;
          if (modal?.amount.remain < buttonCount) {
            setCount(modal?.amount.remain);
            return;
          }else if(modal?.userOrderAbleMax != -1 && modal?.userOrderAbleMax < buttonCount){
            customToast("계정 당 구매수를 초과했어요.");
            return;
          }else if(modal?.isTicket == 1 && modal?.remainUserTicket < (modal.price * buttonCount)) {
            customToast("보유 티켓수가 부족합니다.");
            setCount(Math.floor(modal?.remainUserTicket / modal.price));
            return;
          }else if((modal?.paymentType == 1 && tab == '3') && modal?.remainUserTicket < (modal.typeTicket * buttonCount)) {
            customToast("보유 티켓수가 부족합니다.");
            setCount(Math.floor(modal?.remainUserTicket / modal.typeTicket));
            return;
          }else{
            setCount(buttonCount);
            localStorage.setItem(
                "state",
                JSON.stringify({
                  cardId: modal?.stockId,
                  amount: buttonCount,
                  gift: modal?.gift,
                  type: (modal?.isTicket == 1 || localStorage.getItem('tab') == '3') ? "digital_pack_ticket" : "digital-pack",
                  isTicket: modal?.isTicket,
                  isAddCard: modal?.isAddCard,
                  remain_amount : modal.amount.remain,
                  paymentType : modal?.paymentType,
                })
            );
            navigate(`/payment`);
          }
        },
        onCancel: () => {},
        confirmText: "확인",
        cancelText: "취소",
      });
    }else{
      if (!modal) return;
      if (modal?.amount.remain < buttonCount) {
        customToast("최대 재고 수량을 초과했어요.");
        setCount(modal?.amount.remain);
        return;
      }else if(modal?.userOrderAbleMax != -1 && modal?.userOrderAbleMax < buttonCount){
        customToast("계정 당 구매수를 초과했어요.");
        return;
      }else if(modal?.isTicket == 1 && modal?.remainUserTicket < (modal.price * buttonCount)) {
        customToast("보유 티켓수가 부족합니다.");
        setCount(Math.floor(modal?.remainUserTicket / modal.price));
        return;
      }else if((modal?.paymentType == 1 && tab == '3') && modal?.remainUserTicket < (modal.typeTicket * buttonCount)) {
        customToast("보유 티켓수가 부족합니다.");
        setCount(Math.floor(modal?.remainUserTicket / modal.typeTicket));
        return;
      }else{
        setCount(buttonCount);
        localStorage.setItem(
            "state",
            JSON.stringify({
              cardId: modal?.stockId,
              amount: buttonCount,
              gift: modal?.gift,
              type: (modal?.isTicket == 1 || (modal?.paymentType == 1 && localStorage.getItem('tab') == '3')) ? "digital_pack_ticket" : "digital-pack",
              isTicket: modal?.isTicket,
              isAddCard: modal?.isAddCard,
              paymentType : modal?.paymentType
            })
        );
        navigate(`/payment`);
      }
    }


    // if (!modal) return;
    //   if (modal?.amount.remain < buttonCount) {
    //     customToast("최대 재고 수량을 초과했어요.");
    //     setCount(modal?.amount.remain);
    //     return;
    //   }else if(modal?.userOrderAbleMax != -1 && modal?.userOrderAbleMax < buttonCount){
    //     customToast("계정 당 구매수를 초과했어요.");
    //     return;
    //   }else if(modal?.isTicket == 1 && modal?.remainUserTicket < (modal.price * buttonCount)) {
    //   customToast("보유 티켓수가 부족합니다.");
    //   setCount(Math.floor(modal?.remainUserTicket / modal.price));
    //   return;
    //   }else{
    //     setCount(buttonCount);
    //     localStorage.setItem(
    //         "state",
    //         JSON.stringify({
    //           cardId: modal?.stockId,
    //           amount: buttonCount,
    //           gift: modal?.gift,
    //           type: modal?.isTicket == 1 ? "digital_pack_ticket" : "digital-pack",
    //           isTicket: modal?.isTicket
    //         })
    //     );
    //     navigate(`/payment`);
    //   }
  };

  const handleClose = () => {
    setModal(null);
  };
  const handleSubmit = () => {
    if( (modal?.isTicket == 1 || (modal?.paymentType == 1 && tab == '3')) && modal?.remainUserTicket! < (modal?.price! * (count))){
      customToast("보유 티켓수가 부족합니다.");
      setCount(Math.floor(modal?.remainUserTicket! / modal?.price!));
      return;
    }

    //구매하기 클릭시 [A-5. 결제(디지털 카드팩)] 이동
    localStorage.setItem(
      "state",
      JSON.stringify({
        cardId: modal?.stockId,
        amount: count,
        gift: modal?.gift,
        type: (modal?.isTicket == 1 || localStorage.getItem('tab') == '3')  ? "digital_pack_ticket" : "digital-pack",
        isTicket: modal?.isTicket,
        isAddCard: modal?.isAddCard,
        paymentType : modal?.paymentType
      })
    );
    navigate(`/payment`);
  };

  const isValid = useMemo(() => {
    if (count === 0) return false;
    if ((modal?.amount.remain || 0) < count) return false;
    return true;
  }, [count, modal?.amount]);

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);
  if (modal === null) return null;
  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper>
        <CommonHeader
            title={modal?.gift == 1 ? "디지털 상품팩 선물" : "디지털 상품팩 구매"}
            explain={modal?.gift == 1 ?
                {
                  title: "디지털 상품 선물이란?",
                  text: "구매한 상품을 다른 사람에게 선물하는 방식입니다. ",
                } :
                {
                  title: "디지털 상품 구매란?",
                  text: "구매한 상품을 MY COLLECTION에 추가하는 방식입니다. ",
                }
            }
            isCloseIcon={{onClickClose: handleClose}}
        />

        <div className="container">
          <div className="payment-info-wrapper">
            <div className="product-name">
              {modal.title.length > 16
                  ? modal.title.slice(0, 16) + "..."
                  : modal.title}
            </div>
            <div className="count-wrapper">
              <MinusIcon
                  width={12}
                  height={12}
                  onClick={handleClickCount("minus")}
              />
              <span>{count}</span>
              <PlusIcon
                  width={12}
                  height={12}
                  onClick={handleClickCount("plus")}
              />
            </div>
          </div>
          <Divide/>
          <div className="price-wrapper">
            <div className="price-label">
              {(modal?.isTicket == 1  || localStorage.getItem('tab') == '3') &&
                  <span> (보유 티프 티켓 : {modal?.remainUserTicket}장)</span>
              }
            </div>
            {modal?.isTicket == 1 ? (
                <div className="price-value">
                  {getThousandCommaPrice(modal.price * count)}
                  {modal?.isTicket == 1 ? "장" : "원"}
                </div>
            ) : modal?.paymentType == 1 && localStorage.getItem('tab') == '3' ? (
                <div className="price-value">
                  {getThousandCommaPrice(modal.typeTicket * count)}장
                </div>
            ) : modal?.paymentType == 1 && localStorage.getItem('tab') == '1' ? (
                <div className="price-value">
                  {getThousandCommaPrice(modal.typePrice * count)}원
                </div>
            ) : (
                <div className="price-value">
                  {getThousandCommaPrice(modal.price * count)}원
                </div>
            )}

            {/*<div className="price-value">*/}
            {/*  {getThousandCommaPrice(modal.price * count)}*/}
            {/*  {(modal?.isTicket == 1 || localStorage.getItem('tab') == '3') ? "장" : "원"}*/}
            {/*</div>*/}
            {/*<div className="price-value">*/}
            {/*  {getThousandCommaPrice(modal.price * count)}*/}
            {/*  {modal?.paymentType == 1 ? "장" : "원"}*/}
            {/*</div>*/}
          </div>
          {(modal?.userOrderAbleMax == -1) ? (
                  <>
                    {modal?.stockId == '4525' && (
                        <div className="count-button-wrapper">
                          <div className="count-button-new" onClick={handleButtonCount(10)}>
                            <Button styleType={"line2"}>10회 구매</Button>
                          </div>
                        </div>
                    )}

                    {modal?.stockId != '4525' && (
                        <div className="count-button-wrapper">
                            <div className="count-button" onClick={handleButtonCount(10)}>
                              <Button styleType={"line2"} style={{background: "#ffffff", borderColor: "#838383"}}><p
                                  style={{color: "#838383"}}>10회 구매</p></Button>
                          </div>
                          {modal?.isAddCard == 1 ? (
                              <div className="count-button" onClick={handleButtonCount(100)}>
                                <Button styleType={"line2"}
                                        style={{width: "92%", position: "relative", background: "#3a3a3a"}}>100회 + 10 구매!
                                  <Event style={{position: "absolute", top: "-16", right: "-16"}}/></Button>
                              </div>
                          ) : (
                              <div className="count-button" onClick={handleButtonCount(100)}>
                                <Button styleType={"line2"}>100회 구매</Button>
                              </div>
                          )}
                        </div>
                    )}
                  </>
              ) : (
                  <>
                    <div className="count-button-wrapper">
                      <div className="count-button" style={{width: '100%'}}
                           onClick={handleButtonCount(modal?.userOrderAbleMax)}>
                        <Button styleType={"line2"}>{modal?.userOrderAbleMax}회 구매</Button>
                      </div>
                    </div>
                  </>
              )
          }
        </div>
        <div className="footer">
          {modal?.gift == 1 ?
              <Button disabled={!isValid} onClick={handleSubmit}>
              선물하기
              </Button>
              :
              <Button styleType="sub" disabled={!isValid} onClick={handleSubmit}>
                구매하기
              </Button>
          }
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  & .footer {
    //margin-top: 10px;
    padding: 15px 0;
  }

  & .payment-info-wrapper {
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .product-name {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(112, 112, 112, 1);
  }

  & .count-wrapper {
    width: 100px;
    height: 36px;
    display: flex;

    justify-content: space-around;
    align-items: center;

    border-radius: 10px;
    border: 1px solid rgba(231, 231, 231, 1);
  }

  & .count-button {
    font-size: 12px;
  }

  & .price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 16px;
  }

  & .count-button-wrapper{
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;

    margin-top: 25px;
  }
  
  & .count-button{
    width: 49%;  
  }

  & .count-button-new{
    width: 100%;
  }
  
  & .price-label {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
    
    & > span {
      font-size: 14px;
      color: ${(props) => props.theme.color.gray3};
    }
  }

  & .price-value {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(30, 30, 30, 1);
  }
`;

export default DigitalPackagePaymentModal;
